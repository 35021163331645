<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
// import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanApotekerService from '@/services/LaporanApotekerService'
import GolonganObatService from '@/services/GolonganObatService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanApotekerService()
const golonganService = new GolonganObatService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoading = ref(false)
let form = reactive({
  periode_from: null,
  periode_to: null,
  golongan: null,
  type: 'rekap',
})
let submitted = ref(false)
let list_golongan = ref([])
let list_type = ref([])

const rules = {
  periode_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  periode_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  golongan: {
    required: helpers.withMessage('Golongan harus diisi.', required),
  },
  type: {
    required: helpers.withMessage('Jenis harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await golonganService.get('?sort=nama')
  list_golongan.value = res.data.data
  list_type.value = [
    { id: 'rekap', nama: 'Rekap' },
  ]
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  // loadData()
  onExport()
}

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.periode_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.periode_to).format('YYYY-MM-DD')
  let golonganId = 0
  let type = 'rekap'
  if (form.golongan) golonganId = form.golongan
  if (form.type) type = form.type
  isLoading.value = true
  laporanService
    .exportLaporanReturGolonganPembelian(startDate, endDate, golonganId, type)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-apoteker-retur-pembelian-golongan.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(
        err,
        'Data Apoteker Retur Pembelian per Golongan Obat',
        toast
      )
    })
    .finally(() => ((isLoadingExport.value = false), (isLoading.value = false)))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.type = 'rekap'
  form.golongan = null
  form.periode_from = null
  form.periode_to = null
}
</script>

<template>
  <fx-report-container
    title="LAPORAN RETUR PEMBELIAN PER GOLONGAN OBAT"
    :loading="isLoadingExport"
    @toggle="onToggle"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-3">
            <Dropdown
              v-model="form.golongan"
              :options="list_golongan"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Golongan"
              class="w-full"
              :class="{
                'p-invalid': v$.golongan.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.golongan.$invalid && submitted) ||
                v$.golongan.$pending.$response
              "
              class="p-error"
              >{{ v$.golongan.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.periode_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.periode_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.periode_from.$invalid && submitted) ||
                v$.periode_from.$pending.$response
              "
              class="p-error"
              >{{ v$.periode_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.periode_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.periode_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.periode_to.$invalid && submitted) ||
                v$.periode_to.$pending.$response
              "
              class="p-error"
              >{{ v$.periode_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Dropdown
              v-model="form.type"
              :options="list_type"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Jenis"
              class="w-full"
              :class="{
                'p-invalid': v$.type.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.type.$invalid && submitted) || v$.type.$pending.$response
              "
              class="p-error"
              >{{ v$.type.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button type="submit" icon="pi pi-file-pdf" label="Export" />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
